.week-invoices {
  padding: 20px;
}

.weekly-buttons {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
  max-height: 500px;
  max-width: 50%;
  overflow-y: auto;
  margin: 10px auto;
}

.week-month-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  width: 100%;
}

.week-container {
  margin-bottom: 15px;
  flex-direction: column;
}

.week-details {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 20px;
  border-radius: 8px;
  z-index: 1000;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.week-details h2 {
  margin-top: 0;
}

button {
  margin-top: 10px;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
  .weekly-buttons {
    grid-template-columns: repeat(3, 1fr);
  }
  .week-details {
    grid-column: span 3;
  }
}

@media screen and (max-width: 768px) {
  .weekly-buttons {
    grid-template-columns: repeat(2, 1fr);
  }
  .week-details {
    grid-column: span 2;
  }
}

@media screen and (max-width: 480px) {
  .weekly-buttons {
    grid-template-columns: 1fr;
  }
  .week-details {
    grid-column: span 1;
    width: 100%;
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.week-details .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #666;
}

.close-button:hover {
  color: #333;
}

.search-section {
  width: 20%;
  margin: 20px auto 30px auto;
}