/* Conteneur principal */
.cashpaiement-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  text-align: center;
  font-family: 'Arial', sans-serif;
}

.cashpaiement-container h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* Instructions */
.instructions {
  margin: 20px 0;
  font-size: 1.2rem;
  color: #555;
}

.instruction-step {
  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 10px;
  font-size: 1rem;
  color: #333;
}

.instruction-step svg {
  width: 30px;
  height: 30px;
  color: #4CAF50;
  flex-shrink: 0;
}

/* Animation de chargement */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Bouton de retour */
button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cashpaiement-container {
    padding: 10px;
  }

  .instructions {
    font-size: 1rem;
  }

  .instruction-step {
    font-size: 0.9rem;
  }

  .textWrapper {
    max-width: 80vw;
    position: relative;
    overflow-x: hidden;
  }

  .invertbox {
    position: absolute;
    width: 3rem;
    height: 100%;
  }

  @keyframes move {
    0% { left: 0; }
    50% { left: 20%; }
    100% { left: 0; }
  }
}

/* From Uiverse.io by Uncannypotato69 */ 
.textWrapper {
  height: fit-content;
  min-width: 3rem;
  width: fit-content;
  font-size: 2rem;
  font-weight: 700;
  letter-spacing: 0.25ch;
  position: relative;
  z-index: 0;
  color: rgb(0, 0, 0);
}

.invertbox {
  position: absolute;
  height: 100%;
  aspect-ratio: 1/1;
  left: 0;
  top: 0;
  border-radius: 20%;
  background-color: rgba(130, 130, 130, 0);
  backdrop-filter: invert(100%);
  animation: move 2s ease-in-out infinite;
}

@keyframes move {
  50% {
    left: calc(100% - 3rem);
  }
}
