.admin-dashboard {
    padding: 20px;
  }
  
  .logout-button {
    margin-left: auto;
    padding: 10px;
    background-color: #f44336;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  .logout-button:hover {
    background-color: #d32f2f;
  }
  
  /* Menu principal centré */
  .dropdown-menu {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
    padding: 10px;
  }
  
  .dropdown-item {
    position: relative;
    cursor: pointer;
    padding: 10px 15px;
    background-color: #df4040;
    color: white;
    border-radius: 4px;
    transition: all 0.3s ease;
    min-width: 140px;
    text-align: center;
  }
  
  .dropdown-item:hover {
    background-color: #c41e3a;
  }
  
  /* Bouton Accueil */
  .home-button {
    background-color: #df4040;
    color: white;
  }
  
  .home-button:hover {
    background-color: #c41e3a;
  }
  
  .dropdown-content {
    display: block;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    min-width: 200px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    border-radius: 4px;
    overflow: hidden;
    animation: dropdownFade 0.3s ease-in-out;
  }
  
  .dropdown-content.closing {
    animation: dropdownFadeOut 0.3s ease-in-out forwards;
  }
  
  /* Animation des boutons sans modifier leur style */
  .dropdown-content button {
    animation: slideIn 0.3s ease-in-out;
    animation-fill-mode: both;
  }
  
  .dropdown-content.closing button {
    animation: slideOut 0.3s ease-in-out forwards;
  }
  
  /* Délais pour l'entrée */
  .dropdown-content button:nth-child(1) { animation-delay: 0.1s; }
  .dropdown-content button:nth-child(2) { animation-delay: 0.2s; }
  .dropdown-content button:nth-child(3) { animation-delay: 0.3s; }
  .dropdown-content button:nth-child(4) { animation-delay: 0.4s; }
  .dropdown-content button:nth-child(5) { animation-delay: 0.5s; }
  
  /* Délais pour la sortie */
  .dropdown-content.closing button:nth-child(5) { animation-delay: 0.1s; }
  .dropdown-content.closing button:nth-child(4) { animation-delay: 0.2s; }
  .dropdown-content.closing button:nth-child(3) { animation-delay: 0.3s; }
  .dropdown-content.closing button:nth-child(2) { animation-delay: 0.4s; }
  .dropdown-content.closing button:nth-child(1) { animation-delay: 0.5s; }
  
  @keyframes dropdownFade {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
  
  @keyframes dropdownFadeOut {
    from {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
    to {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
  }
  
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateX(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  
  @keyframes slideOut {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(-10px);
    }
  }
  
  .dropdown-item:hover .dropdown-content {
    visibility: visible;
    opacity: 1;
    transform: translateX(-50%) translateY(0);
    pointer-events: auto;
    transition-delay: 0.4s;
  }
  
  .dropdown-content button {
    width: 80%;
    padding: 12px 20px;
    background-color: transparent;
    color: #333;
    border: none;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.5s ease;
    font-size: 14px;
  }
  
  .dropdown-content button:hover {
    background-color: #f5f5f5;
  }
  
  .section-content {
    margin-top: 20px;
    padding: 20px;
    border-radius: 8px;
    background-color: #f4f4f4;
  }
  
  .logout-confirm-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .logout-confirm-popup {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 90%;
    max-width: 400px;
    text-align: center;
  }
  
  .logout-confirm-popup h3 {
    margin-bottom: 15px;
    color: #333;
  }
  
  .logout-confirm-popup p {
    margin-bottom: 20px;
    color: #666;
  }
  
  .logout-confirm-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  .logout-confirm-buttons button {
    padding: 8px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 500;
    transition: background-color 0.3s;
  }
  
  .confirm-btn {
    background-color: #e74c3c;
    color: white;
  }
  
  .confirm-btn:hover {
    background-color: #c0392b;
  }
  
  .cancel-btn {
    background-color: #95a5a6;
    color: white;
  }
  
  .cancel-btn:hover {
    background-color: #7f8c8d;
  }
  
  /* Styles pour le menu hamburger */
  .hamburger {
    display: block; /* Afficher le bouton hamburger sur tous les écrans */
    font-size: 24px;
    background: none; /* Pas de fond */
    border: 2px solid #df4040; /* Bordure rouge */
    color: #df4040; /* Couleur des barres */
    cursor: pointer;
    border-radius: 50%; /* Forme circulaire */
    width: 40px; /* Largeur du bouton */
    height: 40px; /* Hauteur du bouton */
    transition: background-color 0.3s, border-color 0.3s; /* Transition pour l'animation */
    position: relative; /* Positionner pour les barres */
  }
  
  /* Changement de couleur au survol */
  .admin-dashboard .hamburger:hover {
    background: rgba(223, 64, 64, 0.1); /* Légère couleur de fond au survol */
  }
  
  /* Styles pour les barres du hamburger */
  .hamburger div {
    content: '';
    display: block;
    width: 70%; /* Largeur des barres */
    height: 3px; /* Épaisseur des barres */
    background-color: #df4040; /* Couleur des barres */
    position: absolute; /* Positionner les barres */
    left: 50%; /* Centrer horizontalement */
    transform: translateX(-50%); /* Ajuster pour centrer */
  }
  
  /* Positionnement des barres */
  .hamburger div:nth-child(1) {
    top: 30%; /* Position de la première barre */
    transform: translate(-50%, -50%) rotate(0deg); /* Centrer verticalement */
  }
  
  .hamburger div:nth-child(2) {
    top: 50%; /* Position de la barre du milieu */
    transform: translate(-50%, -50%); /* Centrer verticalement */
  }
  
  .hamburger div:nth-child(3) {
    top: 70%; /* Position de la dernière barre */
    transform: translate(-50%, -50%) rotate(0deg); /* Centrer verticalement */
  }
  
  /* Styles pour le bouton hamburger ouvert */
  .hamburger.open div:nth-child(1) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(45deg); /* Rotation de la première barre et descente */
  }
  
  .hamburger.open div:nth-child(2) {
    opacity: 0; /* Masquer la barre du milieu */
  }
  
  .hamburger.open div:nth-child(3) {
    top: 50%;
    transform: translate(-50%, -50%) rotate(-45deg); /* Rotation de la dernière barre et montée */
  }
  
  /* Styles pour le menu hamburger en mode responsive */
  @media (max-width: 768px) {
    .hamburger {
        display: block; /* Afficher le bouton hamburger sur les petits écrans */
    }

    .dropdown-menu {
        flex-direction: column; /* Changer la direction du menu en colonne */
        position: absolute; /* Positionner le menu */
        top: 70px; /* Ajuster la position */
        left: 0;
        right: 0;
        background-color: #df4040; /* Couleur de fond du menu */
        z-index: 100; /* Assurer que le menu est au-dessus des autres éléments */
        display: none; /* Masquer par défaut */
    }

    .dropdown-menu.open {
        display: flex; /* Afficher le menu lorsque le hamburger est ouvert */
    }

    .dropdown-item {
        width: 100%; /* Prendre toute la largeur */
        text-align: left; /* Alignement à gauche */
    }
  }
  
  /* Styles pour les grands écrans */
  @media (min-width: 769px) {
    .dropdown-menu {
        display: flex; /* Afficher le menu en ligne sur les grands écrans */
        flex-direction: row; /* Direction du menu en ligne */
        margin: 0; /* Supprimer les marges pour un affichage correct */
        visibility: visible; /* S'assurer que le menu est visible */
    }

    .dropdown-item {
        display: block; /* Assurer que les éléments du menu sont visibles */
        margin: 0 10px; /* Espacement entre les éléments */
        color: white; /* Couleur du texte pour les boutons */
    }
  }
  
  .sub-category {
    padding: 10px;
  }
  
  .hamburger-menu {
    display: none; /* Masquer par défaut */
    flex-direction: column; /* Alignement vertical */
    background-color: #df4040; /* Couleur de fond du menu hamburger */
    position: absolute; /* Positionnement absolu */
    top: 70px; /* Ajuster la position */
    left: 0;
    right: 0;
    z-index: 100;
  }
  
  .hamburger-menu.open {
    display: flex;
  }
  
  .hamburger-item {
    padding: 15px 15px 0 15px;
    color: white;
    cursor: pointer;
  }
  
  .hamburger-category-label {
    font-size: 18px;
  }
  
  .hamburger-dropdown-content {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin: -5px 0;
  }
  
  .hamburger-sub-category {
    padding: 5px;
  }
  
  .hamburger-sub-category button {
    background: none; /* Pas de fond */
    color: white; /* Couleur du texte */
    border: none; /* Pas de bordure */
    text-align: left; /* Alignement à gauche */
    cursor: pointer; /* Curseur pointer */
    padding: 5px 0;
  }
  
  .hamburger-sub-category button:hover {
    text-decoration: underline; /* Souligner au survol */
  }
  
  /* Navigation desktop */
  .desktop-nav {
    display: none;
    justify-content: center;
    align-items: center;
    gap: 15px;
    padding: 20px;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .nav-item {
    position: relative;
    padding: 12px 20px;
    background-color: #df4040;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    font-size: 15px;
    text-align: center;
    min-width: 120px;
  }
  
  .nav-item:hover {
    background-color: #c41e3a;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(223, 64, 64, 0.2);
  }
  
  .nav-dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    background-color: white;
    min-width: 220px;
    max-width: 300px;
    width: max-content;
    box-shadow: 0 4px 15px rgba(0,0,0,0.1);
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    z-index: 1000;
    padding: 8px 0;
    transition: opacity 0.3s ease, visibility 0.3s ease;
  }
  
  .nav-dropdown::before {
    content: '';
    position: absolute;
    top: -8px;
    left: 50%;
    transform: translateX(-50%);
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid white;
  }
  
  .nav-item:hover .nav-dropdown {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }
  
  .nav-dropdown button {
    width: 100%;
    padding: 12px 20px;
    text-align: left;
    border: none;
    background: none;
    cursor: pointer;
    color: #333;
    font-size: 14px;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    border-radius: 4px;
    margin: 0 4px;
    width: calc(100% - 8px);
  }
  
  .nav-dropdown button:hover {
    background-color: #df4040;
    color: white;
    padding-left: 25px;
  }

  .access-denied-popup {
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    padding: 20px;
    background-color: rgba(255, 0, 0, 0.8);
    color: white;
    border-radius: 10px;
    text-align: center;
    z-index: 1000;
  }
  
  /* Animation pour l'apparition du dropdown */
  @keyframes dropdownFade {
    from {
      opacity: 0;
      transform: translateX(-50%) translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateX(-50%) translateY(0);
    }
  }
  
  /* Media queries existantes */
  @media (min-width: 1024px) {
    .hamburger {
      display: none;
    }
    
    .hamburger-menu {
      display: none;
    }
    
    .desktop-nav {
      display: flex;
    }

    /* Ajout d'un effet de transition pour le sticky header */
    .desktop-nav.scrolled {
      background-color: rgba(255, 255, 255, 0.95);
      backdrop-filter: blur(5px);
    }
  }
  
  @media (max-width: 1023px) {
    .desktop-nav {
      display: none;
    }
    
    .hamburger {
      display: block;
    }

    .control-home-page {
      width: auto;
    }
  }
  
  /* Ajout d'un espace invisible pour faciliter la navigation vers le sous-menu */
  .nav-item::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    background: transparent;
  }
  
  /* Garder le sous-menu visible pendant un moment après avoir quitté le bouton principal */
  .nav-dropdown:hover {
    opacity: 1;
    visibility: visible;
  }
  
  .nav-item:not(:hover) .nav-dropdown {
    transition-delay: 0.3s;
  }
  
  /* Styles responsifs pour Manage Schedule */
  @media (max-width: 768px) {
    .section-content {
      padding: 10px;
      margin-top: 10px;
    }

    /* Ajustements pour le contenu de ManageSchedule */
    .schedule-container {
      overflow-x: auto;  /* Permet le défilement horizontal */
      -webkit-overflow-scrolling: touch;  /* Défilement fluide sur iOS */
      padding-bottom: 15px;  /* Espace pour la barre de défilement */
    }

    .schedule-table {
      font-size: 14px;  /* Taille de police réduite pour mobile */
    }

    /* Ajustements pour les contrôles de planning */
    .schedule-controls {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 15px;
    }

    .schedule-controls button,
    .schedule-controls select {
      width: 100%;
      padding: 10px;
      font-size: 14px;
    }

    /* Ajustements pour les cellules du planning */
    .schedule-cell {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 8px 4px;
      min-width: 80px;
    }

    .schedule-cell .day-name {
      font-weight: bold;
      margin-bottom: 5px;
    }

    .schedule-cell .time-slot {
      font-size: 12px;
      text-align: center;
    }

    /* Ajustement de l'en-tête du planning */
    .schedule-header {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 10;
    }

    .schedule-header .schedule-cell {
      font-weight: bold;
      background-color: #f5f5f5;
    }

    /* Optimisation de l'espace */
    .schedule-table td {
      vertical-align: top;
    }
  }

  /* Ajustements pour les très petits écrans */
  @media (max-width: 480px) {
    .schedule-container {
      margin: 0 -10px;  /* Compensation du padding du conteneur parent */
    }

    .schedule-controls {
      padding: 0 10px;
    }

    .schedule-cell {
      min-width: 60px;
      padding: 6px 2px;
    }

    .schedule-cell .time-slot {
      font-size: 11px;
    }

    .control-home-page {
      width: auto !important;
    }
  }
  
  /* Styles pour les cartes d'employés */
  .employees-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px;
  }
  
  /* Responsive pour les cartes d'employés */
  @media (max-width: 768px) {
    .employees-grid {
      grid-template-columns: repeat(2, 1fr); /* 2 cartes par ligne */
      gap: 10px;
      padding: 10px;
    }

    .employee-card {
      font-size: 14px; /* Réduire la taille de la police */
    }

    .employee-card img {
      width: 60px;
      height: 60px;
    }

    .employee-card h3 {
      font-size: 16px;
      margin: 5px 0;
    }

    .employee-card p {
      margin: 3px 0;
    }

    .employee-card-actions {
      flex-direction: column;
      gap: 5px;
    }

    .employee-card-actions button {
      width: 100%;
      padding: 8px;
      font-size: 12px;
    }

    .control-home-page {
      width: auto !important;
    }
  }
  
  @media (max-width: 480px) {
    .employees-grid {
      gap: 8px;
      padding: 8px;
    }

    .control-home-page {
      width: auto !important;
    }
  }
  
  @media (max-width: 768px) {
    .form-container select {
      font-size: 14px;
    }

    .form-container input[type="file"]::file-selector-button {
      width: 100%;
    }
    
    .form-container input[type="file"] {
      color: transparent;
    }
    
    .form-container input[type="file"]::-webkit-file-upload-button {
      visibility: visible;
      color: initial;
    }

    .control-home-page {
      width: auto !important;
    }
  }
  
  .control-home-page {
    padding: 10px;
    color: white;
    margin-left: auto;
    margin-right: auto;
    width: 20%;
    display: block;
  }