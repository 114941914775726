/* Conteneur principal */
.cook-interface-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Titre principal */
.cook-interface-title {
  font-size: 2.5rem;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Message de bienvenue */
.cook-interface-container h2 {
  text-align: center;
  margin-bottom: 10px;
  color: #555;
}

/* Bouton de déconnexion */
.logout-icon-spe {
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.logout-icon-spe:hover {
  transform: scale(1.1);
  background-color: #c82333;
}

/* Sections */
.section-title {
  font-size: 1.8rem;
  margin: 30px 0 20px;
  color: #333;
  text-align: center;
}

/* Catégories */
.categories {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.category {
  background: #ffffff;
  border-radius: 8px;
  padding: 20px;
  flex: 1 1 calc(33.333% - 20px); /* 3 colonnes */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 350px;
}

.category h3 {
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 2px solid #ddd;
  padding-bottom: 5px;
}

/* Carte de plat */
.dish-card {
  background: #f4f4f4;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  text-align: left;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dish-card p {
  margin: 0 0 10px;
  color: #555;
  font-size: 1rem;
  text-align: center;
}

/* Boutons d'action */
.action-button {
  display: inline-block;
  padding: 8px 12px;
  font-size: 0.9rem;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.action-button.prepare {
  background-color: #28a745;
  width: 100%;
}

.action-button.prepare:hover {
  background-color: #218838;
}

.action-button.not-ready {
  background-color: #28a745;
  width: 100%;
}

.action-button.not-ready:hover {
  background-color: #218838;
}

.status.prepared, .status.not-ready {
  background-color: #009e2d;
  color: #fff;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  display: block;
  text-align: center;
}

/* Bouton Voir mes heures */
.view-hours-button {
  padding: 15px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  margin: 60px auto 20px auto;
  display: block;
  text-align: center;
  width: 40%;
}

.view-hours-button:hover {
  background-color: #45a049;
}

/* Popup emploi du temps */
.schedule-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond gris transparent */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Popup au-dessus du contenu */
}

.schedule-popup {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 80vh; /* Limiter la hauteur de la popup à 80% de la hauteur de l'écran */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  padding-bottom: 50px;
}

.schedule-popup p {
  text-align: center;
}

/* Conteneur scrollable pour l'emploi du temps */
.schedule-popup-content {
  max-height: 60vh; /* Limiter la hauteur de l'emploi du temps à 60% de la hauteur de l'écran */
  overflow-y: auto; /* Permet le défilement uniquement dans cette zone */
  padding-right: 20px; /* Assurez-vous qu'il y ait un peu d'espace pour la barre de défilement */
}

/* Bouton de fermeture */
.close-popup-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: transparent;
  border: none;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  padding: 5px;
}

.close-popup-button:hover {
  color: red;
}

/* Rendre l'emploi du temps responsive */
.em-schedule-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.em-schedule-table th, .em-schedule-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.em-schedule-table th {
  background-color: #f4f4f4;
}

.em-schedule-table td {
  background-color: #fff;
}

.no-command {
  text-align: center;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .categories {
    flex-direction: column;
    align-items: center;
  }

  .category {
    max-width: 100%;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .cook-interface-title {
    font-size: 2rem;
    margin-top: 60px;
  }

  .section-title {
    font-size: 1.5rem;
  }

  .view-hours-button {
    width: 100%; /* Bouton prend toute la largeur sur mobile */
    font-size: 1.2rem;
  }

  .action-button.prepare {
    font-size: 1.1rem;
  }

  .action-button.not-ready {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .em-schedule-table th, .em-schedule-table td {
    padding: 8px;
  }

  .cook-interface-title {
    margin-top: 60px;
  }

  .category {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .view-hours-button {
    font-size: 1.2rem;
    padding: 15px;
  }

  .action-button.prepare {
    font-size: 1.1rem;
  }

  .action-button.not-ready {
    font-size: 1.1rem;
  }
}
