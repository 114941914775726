body {
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #f7f8fa;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .resume-container {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2, h3 {
    color: #333;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2rem;
    text-align: center;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  h2 {
    font-size: 1.5rem;
    border-bottom: 2px solid #e7e7e7;
    padding-bottom: 10px;
  }
  
  h3 {
    font-size: 1.2rem;
    margin-top: 10px;
  }
  
  .command-details {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    background-color: #fafafa;
  }
  
  .command-details p {
    margin: 10px 0;
    font-size: 1rem;
    line-height: 1.5;
  }
  
  .command-total {
    margin-top: 20px;
    font-size: 1.3rem;
    font-weight: bold;
    text-align: right;
    color: black
  }
  
  .buttons-container {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
.resume-container button {
  flex: 1;
  padding: 12px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background: linear-gradient(135deg, #4caf50, #81c784); /* Vert moderne */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.resume-container button:hover {
  background: linear-gradient(135deg, #2c7a30, #52a756); /* Couleurs plus sombres au survol */
}

.resume-container button.secondary {
  background: linear-gradient(135deg, #d72323, #b31010); /* Orange moderne */
}

.resume-container button.secondary:hover {
  background: linear-gradient(135deg, #ca0000, #bb0202); /* Couleurs plus sombres au survol */
}

/* Style de la popup */
.payment-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fond semi-transparent */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Devant tout le reste */
}

.popup-content-paiement {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  text-align: center;
}

.popup-content-paiement h3 {
  margin-bottom: 20px;
  font-size: 1.5rem;
  color: #333;
}

.popup-content-paiement button {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 12px 20px;
  font-size: 1rem;
  color: #fff;
  background: linear-gradient(135deg, #1976d2, #2196f3); /* Bleu moderne */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-content-paiement button:hover {
  background: linear-gradient(135deg, #1565c0, #1e88e5); /* Couleurs plus sombres au survol */
}

.popup-content-paiement button.cancel {
  background: linear-gradient(135deg, #f44336, #e57373); /* Rouge moderne */
}

.popup-content-paiement button.cancel:hover {
  background: linear-gradient(135deg, #d32f2f, #ef5350); /* Couleurs plus sombres au survol */
}
  
  @media (max-width: 400px) {
    .resume-container {
      margin: 20px 15px;
      padding: 15px;
    }

    .popup-content-paiement {
      width: 90%;
    }
  
    h1 {
      font-size: 1.8rem;
    }
  
    h2 {
      font-size: 1.3rem;
    }
  
    h3 {
      font-size: 1.1rem;
    }
  
    button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }
  
