.search-client-section {
    margin: 20px 0;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .search-bar {
    display: flex;
    gap: 10px;
    margin-bottom: 15px;
    padding: 10px;
    width: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .search-bar input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .search-bar button {
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .search-bar button:hover {
    background-color: #0056b3;
  }
  
  .commandes-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    justify-items: center;
    padding: 20px;
    margin: 20px auto 100px auto;
    max-width: 1200px;
    margin-bottom: 100px;
  }
  
  .commande-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 350px;
    text-align: left;
    transition: transform 0.2s ease-in-out;
  }
  
  .commande-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  }
  
  .commande-section-title {
    text-align: center; /* Centre le texte horizontalement */
    width: 20%;
    font-size: 1.8rem;
    margin: 20px auto; /* Centre l'élément en ajoutant des marges automatiques */
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #002750; /* Ligne soulignée */
    display: inline-block; /* Rend le titre uniquement aussi large que son contenu */
    padding-bottom: 5px;
  } 

  .all-commande-statut {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  .order-state-time {
    margin-bottom: 25px !important;
    text-align: center;
  }

  .my-order-print {
    margin: 5px 0 20px 0;
  }

  .commande-card h3 {
    font-size: 1.5rem;
    color: black;
    margin: 10px;
  }

  .commande-card h4 {
    font-size: 1.2rem;
    color: black;
    margin: 10px;
  }

  .client-name-order {
    text-align: center;
    margin-bottom: 25px;
    font-size: 1.1rem;
    color: #555;
  }

  .server-accepted-name {
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.9rem;
  }
  
  .prepared-dish-label, .prepared-drink-label {
    background-color: #4caf50;
    color: #fff;
    padding: 2px 10px;
    border-radius: 4px;
    font-size: 0.8rem;
    margin-left: 10px;
  }
  
  .accept-action-button {
    width: 90%;
    display: block;
    margin: 0 auto 30px auto;
    color: #fff;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
    transition: background-color 0.2s ease-in-out;
  }

  .total-order-price {
    text-align: center;
    margin: 20px 0 40px 0 !important;
  }
  
  .accept-action-button:hover {
    background-color: #0056b3;
  }
  
  .accept-action-button.accept {
    background-color: #28a745;
  }
  
  .accept-action-button.accept:hover {
    background-color: #218838;
  }
  
  .accept-action-button.complete {
    background-color: #ffc107;
  }
  
  .accept-action-button.complete:hover {
    background-color: #e0a800;
  }
  
  .accept-action-button.served {
    background-color: #6c757d;
  }
  
  .accept-action-button.served:hover {
    background-color: #5a6268;
  }
  
  .commande-loading {
    text-align: center;
    font-size: 1.2rem;
    color: #777;
    margin-top: 20px;
  }

  .no-traitement {
    margin: 20px auto;
  }

  .order-box {
    background-color: rgb(230, 230, 230);
    padding: 10px;
    border: 1px solid rgb(190, 190, 190);
    border-radius: 5px;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .commande-section-title{
      font-size: 1.5rem;
      width: 100%;
    }
  
    .commande-card {
      max-width: 100%;
    }
  }
  
  .card-actions {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }

  .card-actions .accept-action-button {
    width: 45%;
    margin: 0;
  }

  .accept-action-button.edit {
    background-color: #007bff;
  }

  .accept-action-button.edit:hover {
    background-color: #0056b3;
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 90%;
    max-width: 500px;
    max-height: 80vh;
    overflow-y: auto;
  }

  .edit-items-container {
    margin: 20px 0;
  }

  .edit-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
  }

  .quantity-controls {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .quantity-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  .quantity-input {
    width: 50px;
    text-align: center;
    padding: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .modal-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }

  .cancel-button {
    background-color: #6c757d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .save-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
  }

  .modal-total {
    margin-top: 20px;
    text-align: right;
    font-weight: bold;
    font-size: 1.2em;
  }

  .add-item-button {
    background-color: #28a745;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin: 20px 0;
    width: 100%;
  }

  .add-item-form {
    margin: 20px 0;
  }

  .item-select {
    width: 100%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 4px;
  }

  .add-item-actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    margin-top: 20px;
  }

  .add-item-form .quantity-controls {
    margin: 20px 0;
    display: flex;
    justify-content: center;
    gap: 10px;
  }
  
  h3.ready-to-serve-title {
    color: green;
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  