/* Conteneur principal */
.schedule-interface-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'Roboto', sans-serif;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Titre principal */
  .schedule-interface-title {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
    color: #333;
  }
  
  /* Section emploi du temps */
  .schedule-class {
    margin-bottom: 40px;
  }
  
  .schedule-title {
    font-size: 1.8rem;
    color: #555;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .schedule-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
  }
  
  /* Section total des heures */
  .schedule-section {
    margin-top: 40px;
  }
  
  .schedule-section-title {
    font-size: 1.8rem;
    color: #555;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .schedule-total-hours-card {
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 500px;
    margin: 0 auto;
    text-align: center;
    font-size: 1.2rem;
  }
  
  .schedule-section-total-hours {
    font-weight: bold;
    color: #28a745;
  }
  
  /* Réactivité */
  @media (max-width: 768px) {
    .schedule-container {
      gap: 15px;
      flex-direction: column;
      align-items: center;
    }
  
    .schedule-interface-title {
      font-size: 2rem;
    }
  
    .schedule-title, .schedule-section-title {
      font-size: 1.5rem;
    }
  
    .schedule-total-hours-card {
      padding: 15px;
    }
  }
  