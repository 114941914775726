h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  h3 {
    text-align: center;
    margin: 20px 0;
    color: #555;
  }

  div > button {
    margin: 5px;
    padding: 10px 15px;
    font-size: 1rem;
    border: 1px solid #c41e3a;
    border-radius: 4px;
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  div > button:hover {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: translateY(-2px);
  }
  
  div > button:active {
    transform: scale(0.97);
  }
  
  /* Liste des éléments */
  .view-menu-items-list {
    list-style: none;
    padding: 0;
    margin: 20px auto;
    max-width: 1200px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 0 20px;
    justify-items: center;
  }
  
  li {
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
    transition: box-shadow 0.3s ease;
  }
  
  li:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  li img {
    display: block;
    margin: 10px 0;
    border-radius: 4px;
  }
  
  /* Texte dans les éléments */
  li strong {
    color: #333;
    font-size: 1.2rem;
  }
  
  li span {
    font-weight: bold;
    color: #c41e3a;
  }
  
  /* Boutons dans les éléments */
  li button {
    margin: 5px 5px 0 0;
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  li button:first-child {
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: #fff;
  }
  
  li button:first-child:hover {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: translateY(-2px);
  }
  
  li button:nth-child(2) {
    background-color: #f44336; /* Rouge pour supprimer */
    color: #fff;
  }
  
  li button:nth-child(2):hover {
    background-color: #d32f2f;
  }
  
  li button:nth-child(3) {
    background-color: #ffc107; /* Jaune pour activer/désactiver */
    color: #fff;
  }
  
  li button:nth-child(3):hover {
    background-color: #e0a800;
  }
  
  /* Style de la popup */
  .popup-confirmation {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 15px 25px;
    background-color: #4caf50; /* Vert pour les confirmations */
    color: #fff;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: bold;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    opacity: 0;
    animation: fadeInOut 2s forwards;
  }
  
  /* Animation pour le popup */
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translateY(-10px);
    }
    20%, 80% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-10px);
    }
  }

  .popup-confirmation {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #4caf50; /* Couleur verte */
    color: white;
    padding: 15px;
    border-radius: 5px;
    opacity: 1;
    transition: opacity 0.5s ease;
    z-index: 1000; /* S'assurer que la pop-up est au-dessus des autres éléments */
  }
  
  .fade-in {
    opacity: 1;
  }
  
  .fade-out {
    opacity: 0;
  }
  
  .view-menu-items-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
    margin: 20px 0;
  }

  .view-menu-items-buttons button {
    padding: 8px 15px;
    min-width: 80px;
    flex: 0 1 calc(40% - 10px);
    max-width: 150px;
  }

  @media screen and (max-width: 480px) {
    .view-menu-items-buttons {
      width: 100%;
      padding: 0 10px;
    }

    .view-menu-items-buttons button {
      flex: 0 1 calc(45% - 5px);
      max-width: none;
    }
  }
  
  .image-container {
    width: 190px;
    height: 100px;
    margin: 10px auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .menu-item-photo {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .add-photo-button {
    background-color: #4889e9;
  }

  .view-supp-button {
    background-color: #c41e3a;
    margin-top: 15px;
  }
  
  .cart-view {
    text-align: center;
    padding: 25px;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    box-sizing: border-box;
  }
  
  @media screen and (max-width: 768px) {
    .view-menu-items-list {
      grid-template-columns: 1fr;
    }
  }
  
  /* Style pour le texte du modifier */
  .cart-view span {
    display: inline-block;
    margin: 5px 0;
  }
  