.global-view {
  text-align: center;
  padding: 20px;
}

.tables-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.table-svg {
  width: 80px;
  height: 80px;
  transition: fill 0.3s ease;
}

.table-number {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.table-container.occupied .table-svg {
  filter: hue-rotate(120deg);
}

.table-container.occupied .table-number {
  color: red;
}

.modal {
  width: 90%;
  max-width: 600px; /* Limite la largeur maximale */
  max-height: 80vh; /* Hauteur maximale relative à la taille de la fenêtre */
  overflow-y: auto; /* Active le défilement vertical si nécessaire */
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
}

.overlay {
  background: rgba(0, 0, 0, 0.5); /* Ajoute un effet de flou autour de la modale */
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden; /* Empêche le scrolling de la page en arrière-plan */
}

.close-button {
  display: block;
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.tables-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.table-container {
  text-align: center;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease;
}

.table-svg {
  width: 80px;
  height: 80px;
  transition: fill 0.3s ease;
}

.table-container.occupied .table-svg {
  filter: hue-rotate(-45deg); /* Rougir le SVG pour tables occupées */
}

.table-number {
  margin-top: 8px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
}

.status-label {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 5px;
  margin-top: 10px;
  font-weight: bold;
  text-align: center;
  min-width: 80px;
  color: white; /* Couleur du texte par défaut */
}

.status-label.initialized {
  background-color: red;
}

.status-label.accepted {
  background-color: orange;
}

.status-label.completed {
  background-color: blue;
}

.status-label.served {
  background-color: green;
}

.status-label.default {
  background-color: gray;
}

.commande-status {
  text-align: center;
  margin-top: 20px;
}

.commande-separator {
  border: none;
  border-top: 1px solid #ddd; /* Ligne fine et grise */
  margin: 15px 0;
  width: 100%; /* Occupe toute la largeur */
}

.commande-details {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #ddd;
}

.commande-details:last-child {
  border-bottom: none; /* Pas de bordure pour le dernier élément */
}

.global-view {
  text-align: center;
  padding: 20px;
}

.global-view h1 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333;
}

.tables-grid {
  display: grid;
  grid-template-columns: repeat(10, 1fr); /* 10 tables par ligne */
  gap: 20px;
  justify-content: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

.table-container {
  text-align: center;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease, background-color 0.3s ease;
  cursor: pointer;
}

.table-svg {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.table-number {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.table-container.occupied {
  background: #f8d7da;
  border: 2px solid #f5c6cb;
}

.table-container.occupied:hover {
  background: #f5c6cb;
  transform: scale(1.05); /* Mise en avant des tables occupées */
}

.modal {
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.overlay {
  background: rgba(0, 0, 0, 0.5);
}

.close-button {
  margin: 25px auto 10px;
  color: white;
  background: #ac0000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.close-button:hover {
  background: #8b0101;
  color: white;
}

/* Réactivité */
@media (max-width: 1200px) {
  .tables-grid {
    grid-template-columns: repeat(5, 1fr); /* 5 tables par ligne */
  }
}

@media (max-width: 768px) {
  .tables-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 tables par ligne */
  }
}

@media (max-width: 480px) {
  .tables-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 tables par ligne */
  }
}

.table-container {
  text-align: center;
  padding: 15px;
  background: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  display: flex; /* Active Flexbox */
  flex-direction: column; /* Place les éléments en colonne */
  align-items: center; /* Centre horizontalement */
  justify-content: center; /* Centre verticalement */
  transition: transform 0.2s ease, background-color 0.3s ease;
  cursor: pointer;
}

.table-svg {
  width: 60px;
  height: 60px;
  margin-bottom: 10px;
}

.table-number {
  font-size: 16px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.no-result-table {
  text-align: center;
}

.table-orders-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.table-orders-container {
  background: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
}

.commande-details {
  background: white;
  padding: 15px;
  border-radius: 6px;
  margin-bottom: 20px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.commande-items {
  margin-bottom: 15px;
}

.commande-item {
  font-size: 16px;
  margin-bottom: 15px;
  color: #444;
}

.cuisson {
  font-style: italic;
  color: #555;
}

.commande-total {
  font-size: 18px;
  font-weight: bold;
  color: #01172e;
  margin-top: 10px;
  text-align: right;
  margin-right: 20px;
}

.commande-server {
  margin-top: 10px;
  font-size: 16px;
  color: #444;
}

.commande-server span {
  font-weight: bold;
}

.commande-separator {
  border: none;
  border-top: 1px solid #ddd;
  margin: 15px 0;
}

.total-table {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
  margin-top: 20px;
  color: black;
}

.no-result-table {
  font-size: 18px;
  color: #999;
  text-align: center;
  margin-top: 20px;
}

.close-button {
  display: block;
  margin: 30px auto 0;
  padding: 12px 25px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  background: #dc3545;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.close-button:hover {
  background: #c82333;
}
