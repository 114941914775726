/* Satisfaction Container */
.satisfaction-container {
  background-color: #f9f9f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
}

/* Heading and Subheading */
.satisfaction-container h2 {
  font-size: 28px;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.satisfaction-container p {
  font-size: 16px;
  color: #666;
  margin-bottom: 30px;
}

/* Rating Group Styling */
.rating-group {
  text-align: left;
  margin-bottom: 20px;
}

.rating-group label {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

.stars {
  display: flex;
  gap: 10px;
  justify-content: center;
  cursor: pointer;
}

.star {
  font-size: 35px;
  color: #ddd;
  transition: color 0.3s ease;
}

.star.selected {
  color: #f5a623;
}

/* Feedback Area */
.feedback-group {
  text-align: left;
  margin-bottom: 30px;
}

.feedback-group label {
  font-size: 18px;
  color: #333;
  font-weight: 600;
  margin-bottom: 10px;
  display: block;
}

textarea {
  width: 100%;
  min-height: 100px;
  padding: 12px;
  border: 2px solid #ccc;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

/* Submit Button */
.satisfaction-submit-btn {
  background-color: #007bff;
  color: white;
  padding: 12px 25px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.satisfaction-submit-btn:hover {
  background-color: #0056b3;
}

/* Success and Error Messages */
p.satisfaction-success-message {
  color: #28a745 !important;
  font-weight: 600;
  margin-top: 15px;
  font-size: 18px;
}

p.satisfaction-error-message {
  color: #e74c3c !important;
  font-weight: 600;
  margin-top: 15px;
  font-size: 16px;
}

/* Responsive Design */
@media (max-width: 600px) {
  .satisfaction-container {
    padding: 15px;
  }

  .star {
    font-size: 30px;
  }

  .satisfaction-submit-btn {
    width: 100%;
  }
}
