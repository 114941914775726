table {
  width: 80%;
  margin: 20px auto;
  border-collapse: collapse;
  text-align: left;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background-color: #f9f9f9;
}

tr:hover {
  background-color: #f1f1f1;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

button.delete-table-hour {
  background-color: rgb(190, 0, 0);
}

td.actions-button-hour {
  text-align: center;
}

.add-hour-supp {
  width: 40%;
  margin: 30px auto;
  display: block;
}

.label-add-extra {
  text-align: center;
  margin-bottom: 10px;
}

.label-add-extra select, .label-add-extra input {
  width: 40%;
}

/* Ajout des styles pour la responsivité */
@media screen and (max-width: 768px) {
  .work-table-container {
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch; /* Pour un défilement fluide sur iOS */
  }

  .label-add-extra select, .label-add-extra input {
    width: 70%;
  }

  table {
    width: 100%;
    min-width: 600px; /* Largeur minimale pour assurer que la table ne se compresse pas trop */
    margin: 0;
  }
}
