/* Conteneur principal centré */
.client-form-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 95.5vh; /* Centre verticalement dans la page */
  padding: 20px;
  background: linear-gradient(135deg, #f9f9f9, #eceff1); /* Dégradé léger */
  font-family: 'Roboto', sans-serif;
}

.your_commande {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 700;
  margin-bottom: 20px;
  text-decoration: underline;
}

/* Titre */
.client-form-title {
  font-size: 2.2rem;
  color: #333;
  margin-bottom: 18px;
  text-align: center;
  font-weight: bold;
}

/* Formulaire stylisé */
.client-form {
  background: #fff;
  padding: 35px;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  width: 100%;
  animation: fadeIn 0.8s ease-out;
  transition: all 0.3s ease;
}

/* Groupes de champs */
.client-input-group {
  display: flex;
  flex-direction: column;
}

.client-input-group label {
  font-size: 1rem;
  font-weight: 500;
  color: #555;
  margin-bottom: 8px;
}

.client-input-group input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

/* Bouton d'envoi */
.client-submit-btn {
  margin-bottom: 25px;
  background: linear-gradient(90deg, #ff4b4b, #d10000);
  color: #fff;
  font-size: 1rem;
  padding: 14px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  text-transform: uppercase;
  font-weight: bold;
}

.client-submit-btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(209, 0, 0, 0.3);
}

/* Message d'erreur */
.client-error-message {
  text-align: center;
  color: #ef272b;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
}

/* Message de succès */
.client-success-message {
  background-color: #e6ffe6;
  color: #006400;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #98FB98;
  font-size: 0.9rem;
  animation: fadeIn 0.5s ease-out;
  margin-bottom: 20px;
}

/* Animation d'apparition */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes popIn {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Responsive pour les petits écrans */
@media (max-width: 480px) {
  .client-form {
    padding: 25px;
  }

  .client-form-title {
    font-size: 1.8rem;
  }

  .client-submit-btn {
    font-size: 0.9rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.client-access-error {
  background-color: #fff3f3;
  color: #ff4d4f;
  padding: 12px;
  border-radius: 8px;
  text-align: center;
  border: 1px solid #ffccc7;
  font-size: 0.9rem;
  animation: fadeIn 0.5s ease-out;
  margin-bottom: 20px;
}
