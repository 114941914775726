/* Menu.css */

/* Global Styles */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
  
  html, body {
    width: 100%; /* Empêche tout débordement de largeur */
    overflow-x: hidden; /* Empêche le défilement horizontal */
  }
  
  body {
    background-color: #f9f9f9;
    color: #333;
  }
  
  /* Header */
  .menu-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 1000;
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    padding: 20px 15px;
    color: white;
    box-shadow: 0 2px 8px rgba(196, 30, 58, 0.15);
  }
  
  .menu-first-title {
    font-size: 2.2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
    color: white;
  }
  
  /* Navigation */
  .menu-nav {
    max-width: 500px;
    margin: 0 auto;
  }
  
  .menu-nav-list {
    display: flex;
    justify-content: center;
    gap: 10px;
    list-style: none;
    flex-wrap: wrap;
  }
  
  .menu-nav-item {
    padding: 8px 15px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.4s ease;
    font-weight: 500;
    font-size: 0.9rem;
    backdrop-filter: blur(5px);
  }
  
  .menu-nav-item:hover {
    background: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
  }
  
  /* Main Content */
  main {
    padding: 30px 20px;
    background: #f9f9f9;
    min-height: calc(100vh - 200px);
    margin-top: 140px;
  }
  
  .menu-title {
    font-size: 2rem;
    color: #333;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 40px;
    font-weight: 700;
  }
  
  .product-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: center;
  }
  
  .product-item {
    width: calc(50% - 1rem);
    min-width: 250px;
    max-width: 350px;
    flex-shrink: 0;
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    gap: 15px;
    transition: all 0.5s ease;
  }
  
  .product-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
  }
  
  .product-photo-container {
    width: 100%;
    height: 200px;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;
  }
  
  .no-image-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    color: #999;
    font-size: 1rem;
    white-space: nowrap;
    width: 100%;
    text-align: center;
    font-style: italic;
  }
  
  .product-photo {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-item h3 {
    font-size: 1.4rem;
    color: #333;
    margin: 0;
    text-align: center;
  }
  
  .product-item .price {
    font-size: 1.2rem;
    color: #666;
    margin: 0;
    text-align: center;
  }
  
  .product-item .description {
    color: #666;
    line-height: 1.6;
    margin: 0;
    text-align: center;
    flex-grow: 1;
  }
  
  .add-to-cart-btn {
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 25px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.4s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: 100%;
    font-size: 1rem;
  }
  
  .add-to-cart-btn:hover:not(.disabled) {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(209, 0, 0, 0.3);
  }
  
  .add-to-cart-btn.disabled {
    background: #ccc;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  hr {
    margin: 20px 0;
    border: 1px solid #ccc;
  }
  
  /* Cart Section */
  .menu-cart-section {
    background: white;
    border-radius: 15px;
    padding: 25px;
    margin-top: 40px;
    box-shadow: 0 4px 15px rgba(196, 30, 58, 0.1);
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .menu-cart-section h2 {
    color: #333;
    font-size: 1.6rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .menu-cart-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    background: #fff8f8;
    border-radius: 10px;
    margin-bottom: 10px;
    transition: transform 0.3s ease;
    border: 1px solid rgba(196, 30, 58, 0.1);
  }
  
  .menu-cart-item-info {
    flex: 1;
  }
  
  .menu-cart-item-price {
    color: #c41e3a;
    font-weight: 600;
    margin-top: 5px;
  }
  
  .menu-cart-quantity-controls {
    display: flex;
    align-items: center;
    gap: 12px;
    background: white;
    padding: 5px 10px;
    border-radius: 20px;
    border: 1px solid rgba(196, 30, 58, 0.2);
  }
  
  .quantity-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 2px solid #c41e3a;
    background: white;
    color: #c41e3a;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    padding: 0;
    line-height: 1;
    font-size: 16px;
  }
  
  .quantity-btn:hover {
    transform: scale(1.1);
    background: #c41e3a;
    color: white;
  }
  
  .quantity-display {
    min-width: 20px;
    text-align: center;
    font-weight: 600;
    color: #333;
  }
  
  .menu-cart-item:hover {
    transform: translateX(5px);
    border-color: rgba(196, 30, 58, 0.2);
  }
  
  .menu-remove-btn {
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.4s ease;
  }
  
  .menu-remove-btn:hover {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: scale(1.05);
    box-shadow: 0 2px 8px rgba(196, 30, 58, 0.2);
  }
  
  .order-btn {
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: white;
    width: 100%;
    padding: 12px;
    border: none;
    border-radius: 25px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.4s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 20px;
  }
  
  .order-btn:hover:not(:disabled) {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(196, 30, 58, 0.3);
  }
  
  .order-btn:disabled {
    background: #ccc;
    cursor: not-allowed;
  }
  
  /* Popup Styles */
  .menu-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    min-width: 300px;
  }
  
  .menu-popup h3 {
    color: #333;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .menu-popup p {
    text-align: center;
    margin-bottom: 20px;
    color: #666;
  }
  
  /* Conteneur pour les boutons */
  .menu-popup-buttons {
    display: flex !important;
    flex-direction: row !important;
    justify-content: center;
    gap: 10px;
    width: 100%;
    margin-top: 15px;
  }
  
  .menu-popup button {
    flex: 0 1 120px; /* flex-grow: 0, flex-shrink: 1, flex-basis: 120px */
    padding: 12px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 600;
    transition: all 0.3s ease;
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: white;
    margin: 0; /* Réinitialise les marges */
  }
  
  .menu-popup button:last-child {
    background: #f5f5f5;
    color: #333;
  }
  
  .menu-popup button:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(196, 30, 58, 0.2);
  }
  
  @keyframes slideIn {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .menu-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2px);
    z-index: 999;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  .menu-popup-confirmation {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
    background: white;
    color: #333;
    padding: 25px 35px;
    border-radius: 15px;
    box-shadow: 0 4px 20px rgba(196, 30, 58, 0.2);
    animation: fadeInOut 2s ease-in-out forwards;
    z-index: 1000;
    text-align: center;
    border: 2px solid rgba(196, 30, 58, 0.1);
    min-width: 300px;
    margin: 0;
    width: auto;
    max-width: 90%;
  }

  .cart-logo-container {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .cart-logo {
    width: 40px;
    height: 40px;
  }
  
  .cart-count {
    background-color: red;
    color: white;
    font-weight: bold;
    font-size: 14px;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -5px;
    right: -5px;
  }  
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeInOut {
    0% {
      opacity: 0;
      transform: translate(-50%, -55%);
    }
    15% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    85% {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
    100% {
      opacity: 0;
      transform: translate(-50%, -45%);
    }
  }
  
  .menu-popup-confirmation p {
    font-size: 1.1rem;
    font-weight: 500;
    margin: 0;
    color: #c41e3a;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    html, body {
      overflow-x: hidden; /* Empêche tout défilement horizontal */
    }
  
    .menu-nav {
      flex-direction: column;
    }
  
    .menu-nav-item {
      margin: 10px 0;
    }
  
    .product-list {
      gap: 1.5rem;
      padding: 1.5rem;
    }
  
    .product-item {
      width: calc(50% - 0.75rem); /* 2 cards par ligne sur tablette */
      min-width: 250px;
    }
  
    .menu-cart-section {
      padding: 15px;
    }
  
    .menu-cart-item {
      flex-direction: column;
      text-align: left;
    }
  
    .menu-header {
      padding: 15px 10px; /* Réduit le padding vertical et horizontal */
    }
  
    .menu-first-title {
      margin-top: 10px;
      font-size: 2.2rem;
      margin-bottom: 15px; /* Réduit la marge sous le titre */
    }
  
    main {
      margin-top: 120px; /* Ajuste la marge du contenu principal */
    }
  }
  
  @media (max-width: 480px) {
    .menu-first-title {
      margin-top: 10px;
      font-size: 1.8rem;
      margin-bottom: 0;
    }
  
    .menu-title {
      font-size: 1.2rem;
    }
  
    .menu-remove-btn, .order-btn {
      padding: 8px 16px;
    }
  
    .menu-nav-list {
      gap: 8px;
      padding: 15px 10px;
    }
  
    .menu-nav-item {
      padding: 6px 12px;
      font-size: 0.85rem;
    }
  
    .menu-header {
      padding: 12px 8px; /* Encore plus petit sur mobile */
    }
  
    main {
      margin-top: 150px; /* Ajuste encore la marge du contenu principal */
    }

    hr {
      margin: 0;
    }
  }
  
  /* Responsive Styles pour le panier */
  @media (max-width: 768px) {
    .menu-cart-section {
      padding: 15px;
    }

    .cart-logo {
      margin-top: -10px;
    }
  
    .menu-cart-item {
      flex-direction: column;
      text-align: left;
    }
  
    .menu-remove-btn {
      margin-top: 10px;
      width: 100%;
    }
  
    .menu-cart-section h2 {
      font-size: 1.6rem;
    }
  
    .order-btn {
      padding: 12px;
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .menu-cart-section {
      padding: 20px;
      margin-top: 20px;
    }

    .cart-logo {
      margin-top: -10px;
    }
  
    .menu-cart-item {
      padding: 12px;
    }
  
    .menu-cart-section h2 {
      font-size: 1.2rem;
      margin-bottom: 15px;
    }
  
    .menu-remove-btn {
      padding: 6px 12px;
      font-size: 0.9rem;
    }
  
    .order-btn {
      padding: 12px;
      font-size: 0.9rem;
      border-radius: 10px;
    }
  }
  
  /* Message panier vide */
  .menu-cart-section .empty-cart-message {
    background-color: #fff8f8;
    color: #c41e3a;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgba(196, 30, 58, 0.1);
  }
  
  /* Bouton déconnecter */
  button[onclick="handleReset"],
  .change-table-btn {
    background: linear-gradient(135deg, #ff6b6b, #c41e3a);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-weight: 500;
    position: fixed;
    bottom: 20px;
    left: 20px;
    z-index: 1000;
  }
  
  button[onclick="handleReset"]:hover,
  .change-table-btn:hover {
    background: linear-gradient(135deg, #c41e3a, #ff6b6b);
    transform: translateY(-2px);
    box-shadow: 0 2px 8px rgba(196, 30, 58, 0.2);
  }
  
  /* Ajustement pour mobile */
  @media (max-width: 480px) {
    button[onclick="handleReset"],
    .change-table-btn {
      padding: 8px 16px;
      font-size: 0.9rem;
      bottom: 15px;
      left: 15px;
    }
  }
  
  /* Total et bouton commander */
  .cart-total {
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 20px 0;
  }
  
  .order-btn:disabled {
    background: #cccccc;
    transform: none;
    box-shadow: none;
  }
  
  /* Bouton déconnecter dans le header */
  .logout-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 8px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  @media (max-width: 480px) {
    .logout-btn {
      top: 0;
      font-size: 1.2rem;
    }
  }
  
  .product-item.inactive .product-photo-container {
    position: relative;
  }
  
  .product-item.inactive .product-photo-container img {
    filter: blur(3px);
  }
  
  .product-item.inactive .product-photo-container::after {
    content: "VICTIME DE SON SUCCÈS";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-30deg);
    color: #c41e3a;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 40px;
    border: 3px solid #c41e3a;
    border-radius: 8px;
    text-transform: uppercase;
    letter-spacing: 2px;
    white-space: nowrap;
    filter: none;
    z-index: 1;
  }
  
  .product-item.inactive .no-image-text {
    filter: none;
  }
  
  @media (max-width: 480px) {
    .quantity-btn {
      width: 20px;
      height: 20px;
      font-size: 12px;
      border-width: 1px;
    }
  }
  
  /* Ajustements pour le panier sur petits écrans */
  @media (max-width: 480px) {
    .menu-cart-item {
        flex-direction: row; /* Aligne les éléments horizontalement */
        justify-content: space-between; /* Espace entre le nom et le prix */
        align-items: center; /* Centre verticalement les éléments */
    }

    .menu-cart-item-info {
        flex: 1; /* Permet au nom du plat de prendre l'espace disponible */
        margin-right: 10px; /* Ajoute un espacement entre le nom et le prix */
    }

    .menu-cart-item-price {
        font-size: 1rem; /* Ajuste la taille de la police pour le prix */
        color: #c41e3a; /* Couleur du prix */
    }
  }
  
  /* Réduction de l'espace entre les boutons de quantité et la quantité affichée */
  @media (max-width: 480px) {
    .menu-cart-quantity-controls {
        gap: 5px; /* Réduction de l'espace entre les éléments */
    }

    .quantity-display {
        margin: 0 5px; /* Réduction de la marge autour de l'affichage de la quantité */
    }
  }
  
  /* Ajustements pour l'affichage des plats sur petits écrans */
  @media (max-width: 480px) {
    .product-list {
        display: flex; /* Assurez-vous que la liste utilise flexbox */
        flex-wrap: wrap; /* Permet le retour à la ligne */
        justify-content: space-between; /* Espace entre les éléments */
        padding: 0 5px; /* Réduction du padding de la liste des produits */
        gap: 5px; /* Réduction de l'espace entre les éléments */
    }

    .product-item {
        width: calc(90% - 20px); /* Réduction de la largeur pour 2 plats par ligne */
        min-width: 130px; /* Largeur minimale pour les petits écrans */
        height: 450px; /* Ajustement de la hauteur pour un meilleur rendu */
        padding: 30px; /* Ajout de padding interne aux éléments de produit */
        margin: 0; /* Suppression de la marge externe pour éviter l'espace supplémentaire */
        margin-bottom: 30px;
        margin-left: auto;
        margin-right: auto;
    }

    .add-to-cart-btn {
        padding: 12px 0 12px 0; /* Réduction de la taille du bouton Ajouter au panier */
        font-size: 0.85rem; /* Réduction de la taille de la police du bouton */
        width: 100%;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px;
    }

    .product-item h3 {
        font-size: 1.4rem; /* Réduction de la taille du titre */
    }

    .product-item .price {
        font-size: 1rem; /* Réduction de la taille du prix */
    }

    .product-item .description {
        font-size: 0.9rem; /* Réduction de la taille de la description */
    }
  }
  
  /* Ajustements pour le header sur petits écrans */
  @media (max-width: 480px) {
    .menu-nav {
        display: flex; /* Utiliser flexbox pour aligner les boutons sur la même ligne */
        flex-wrap: wrap; /* Permet le retour à la ligne si nécessaire */
        justify-content: space-between; /* Espace entre les boutons */
    }

    .menu-nav-item {
        padding: 9px 15px; /* Réduction de la taille des boutons */
        text-align: center;
        font-size: 0.9rem; /* Réduction de la taille de la police */
        flex: 0.3 1; /* Permet aux boutons de s'étendre également */
        margin: 2px; /* Ajout d'une petite marge entre les boutons */
    }
  }
  
  /* Ajustements pour le texte "VICTIME DE SON SUCCÈS" sur petits écrans */
  @media (max-width: 480px) {
    .product-list .product-item.inactive .product-photo-container::after {
        font-size: 0.8rem; /* Taille de la police */
        padding: 5px; /* Ajustement du padding */
        border: 1px solid #c41e3a; /* Couleur de la bordure */
        border-radius: 5px; /* Ajustement du rayon de la bordure */
        top: 50%; /* Centrer verticalement */
        left: 50%; /* Centrer horizontalement */
        white-space: nowrap; /* Empêche le retour à la ligne */
    }
  }

  /* Ajustements pour les plats sur petits écrans */
  @media (max-width: 480px) {
    .product-list .product-photo-container {
        height: 170px !important; /* Réduction de la hauteur de l'image à 70px */
        margin-bottom: 10px;
    }

    .product-item.inactive .product-photo-container::after {
        font-size: 0.9rem; /* Ajustement de la taille de la police pour le texte inactif */
        padding: 3px; /* Ajustement du padding si nécessaire */
    }
  }

  /* Ajustements pour le texte "Pas de visuel disponible" sur petits écrans */
  @media (max-width: 480px) {
    .product-item.inactive .no-image-text {
        font-size: 0.8rem; /* Réduction de la taille de la police */
        padding: 5px; /* Ajustement du padding pour plus d'espace */
        width: 100%; /* S'assurer que le texte utilise toute la largeur */
        text-align: center; /* Centrer le texte */
        white-space: nowrap; /* Empêche le retour à la ligne */
    }
  }

  /* Ajustements pour le texte "Pas de visuel disponible" */
  .product-item .no-image-text {
    font-size: 0.8rem; /* Réduction de la taille de la police */
    padding: 5px; /* Ajustement du padding pour plus d'espace */
    width: 100%; /* S'assurer que le texte utilise toute la largeur */
    text-align: center; /* Centrer le texte */
    white-space: nowrap; /* Empêche le retour à la ligne */
  }

  /* Ajustements pour les petits écrans */
  @media (max-width: 480px) {
    .product-item .no-image-text {
        font-size: 0.8rem; /* Réduction supplémentaire de la taille de la police sur petits écrans */
    }
  }

  @media (max-width: 480px) {
    .menu-cart-section p {
      font-size: 1rem;
    }
  }
