.month-invoices {
    padding: 20px;
  }
  
  .monthly-buttons {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
    padding: 20px;
    max-height: 500px;
    max-width: 50%;
    overflow-y: auto;
    margin: 10px auto;
  }
  
  .month-button {
    padding: 10px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 100%;
    text-align: center;
  }
  
  .month-button:hover {
    background-color: #2980b9;
  }
  
  .month-details {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ecf0f1;
    padding: 20px;
    border-radius: 8px;
    z-index: 1000;
    max-width: 80%;
    max-height: 80vh;
    overflow-y: auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  
  .month-details .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #666;
  }
  
  .close-button:hover {
    color: #333;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 1200px) {
    .monthly-buttons {
      grid-template-columns: repeat(3, 1fr);
    }
    .month-details {
      grid-column: span 3;
    }
  }
  
  @media screen and (max-width: 768px) {
    .monthly-buttons {
      grid-template-columns: repeat(2, 1fr);
    }
    .month-details {
      grid-column: span 2;
    }
  }
  
  @media screen and (max-width: 480px) {
    .monthly-buttons {
      grid-template-columns: 1fr;
    }
    .month-details {
      grid-column: span 1;
      width: 100%;
    }
  }
  