/* src/components/Confirmation.css */

body {
  background-color: #f4f5f7;
  margin: 0;
  padding: 0;
}

.confirmation-page {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.confirmation-page h1 {
  font-size: 2rem;
  color: black;
  margin-bottom: 20px;
}

.confirmation-page h2 {
  font-size: 1.8rem;
  color: #34495e;
  margin: 30px 0 20px;
}

.confirmation-page h3 {
  font-size: 1.2rem;
  color: #2c3e50;
}

.confirmation-page p {
  font-size: 1rem;
  color: #7f8c8d;
  margin: 10px 0;
}

.confirmation-page button {
  width: 100%;
}

.commande-details {
  text-align: left;
  margin: 20px 0;
  border-top: 1px solid #ecf0f1;
  padding-top: 20px;
}

.item {
  margin-bottom: 10px;
}

button {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin: 10px 5px;
}

button:disabled {
  cursor: not-allowed;
  background-color: #bdc3c7;
}

button.quit-btn {
  background-color: #e74c3c;
  color: white;
}

button.quit-btn:hover {
  background-color: #c0392b;
}

.error-message {
  color: #e74c3c;
  font-size: 1.2rem;
}

.satisfaction-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  padding: 20px;
  z-index: 999;
  width: 80%;
  max-width: 400px;
  overflow-y: auto;
}

.satisfaction-popup-content {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 350px;
  padding: 10px;
  overflow-y: auto;
}

.satisfaction-popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;
  width: 90%;
  max-width: 500px;
  height: 80%;
}

.satisfaction-popup-content form {
  display: flex;
  flex-direction: column;
}

.satisfaction-popup-content input {
  margin: 5px 0;
  padding: 8px;
}

.satisfaction-popup-content button {
  margin-top: 10px;
  padding: 10px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.satisfaction-popup-content .close-button {
  background-color: #f44336;
  margin-top: 10px;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #3498db;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .confirmation-page {
    margin: 40px 20px;
    padding: 15px;
  }

  .confirmation-page h1, h2, h3 {
    font-size: 1.4rem;
  }

  .confirmation-page p {
    font-size: 0.9rem;
  }

  .confirmation-page button {
    width: 100%;
    margin: 10px 0;
  }
}

.close-satisfaction {
  width: 70%;
  margin-top: 25px !important; 
}

.send-ticket {
  background: linear-gradient(135deg, #0c55ad, #063e7a) !important; 
  border: 1px solid #02194f;
}

.quit-popup {
  position: fixed;
  top: 15%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #59e17984;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  z-index: 9999;
  font-size: 18px;
  text-align: center;
  width: 350px;
  max-width: 80%;
  opacity: 0;
  animation: fadeIn 1s forwards;
  border: 1px solid rgb(50, 50, 50);
}

.quit-popup p {
  color: black;
}

.satisfaction-popup-content {
  width: 100%;
  overflow-y: auto;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.satisfaction-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
}

.no-scroll {
  overflow: hidden;
}
