/* src/components/admin.css */

body {
    font-family: 'Roboto', Arial, sans-serif;
    background-color: #f7f8fa;
    color: #333;
    margin: 0;
    padding: 0;
  }
  
  .form-container {
    max-width: 600px;
    margin: 40px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 30px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
  }
  
  input,
  textarea,
  select {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  textarea {
    resize: vertical;
    height: 100px;
  }
  
  .form-container button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #d80000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .form-container button:hover {
    background-color: #c41e3a;
    transform: scale(1.05);
  }
  
  .form-container button:active {
    background-color: #c41e3a;
  }
  
  .popup-confirmation {
    margin-top: 20px;
    padding: 15px;
    background-color: #2ecc71;
    color: #ffffff;
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    opacity: 0;
    animation: fade-in 0.5s forwards;
  }
  
  .popup-confirmation.fade-in {
    opacity: 1;
  }
  
  @keyframes fade-in {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @media (max-width: 768px) {
    .form-container {
      margin: 20px 15px;
      padding: 15px;
    }
  
    h2 {
      font-size: 1.5rem;
    }
  
    .form-container button {
      font-size: 0.9rem;
      padding: 10px;
    }
  }

  
  /* Styles pour AddServerForm */

/* Section du formulaire */
.server-form-container {
    max-width: 700px;
    margin: 40px auto;
    padding: 25px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .server-form-container h2 {
    text-align: center;
    font-size: 1.8rem;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .server-form-container form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .server-form-container label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #34495e;
  }
  
  .server-form-container input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    transition: border-color 0.3s ease;
  }
  
  .server-form-container button {
    padding: 12px 20px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #d80000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .server-form-container button:hover {
    background-color: #c41e3a;
    transform: scale(1.05);
  }
  
  .server-form-container button:active {
    background-color: #c41e3a;
  }
  
  .server-form-container .error-message {
    color: red;
    text-align: center;
    margin-top: -10px;
  }
  
  .server-form-container .success-message {
    color: green;
    text-align: center;
    margin-top: -10px;
  }
  
  /* Liste des serveurs */
  .server-list-container {
    margin-top: 30px;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .server-list-container h3 {
    font-size: 1.5rem;
    color: #2c3e50;
    margin-bottom: 15px;
  }
  
  .server-list-container ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .server-list-container li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
    padding: 10px 15px;
    border-radius: 5px;
    margin-bottom: 10px;
    transition: background-color 0.3s ease;
  }
  
  .server-list-container li:hover {
    background-color: #f1f1f1;
  }
  
  .server-list-container button {
    padding: 8px 12px;
    font-size: 0.9rem;
    font-weight: bold;
    color: #fff;
    background-color: #e74c3c;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .server-list-container button:hover {
    background-color: #c0392b;
  }
  
  .server-list-container button:active {
    background-color: #a93226;
  }
  
  .server-list-container button:first-of-type {
    background-color: #d80000;
    margin-right: 10px;
  }
  
  .server-list-container button:first-of-type:hover {
    background-color: #c41e3a;
  }
  
  .server-list-container button:first-of-type:active {
    background-color: #c41e3a;
  }
  
  @media (max-width: 768px) {
    .server-form-container,
    .server-list-container {
      margin: 20px 15px;
      padding: 15px;
    }
  
    .server-form-container h2,
    .server-list-container h3 {
      font-size: 1.4rem;
    }
  
    .server-list-container li {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
      font-size: 0.9rem;
    }
  
    .server-list-container button {
      width: 100%;
      font-size: 0.8rem;
    }
  }

  /* Bouton rouge pour la déconnexion */
.logout-button {
    padding: 10px 15px;
    font-size: 1rem;
    font-weight: bold;
    color: #fff;
    background-color: #e74c3c; /* Rouge */
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .logout-button:hover {
    background-color: #c0392b;
  }
  
  .logout-button:active {
    background-color: #a93226;
  }

  .dish-flex {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }

  .dish-flex > div {
    flex: 1;
    min-width: 0;
  }

  .dish-flex input {
    width: 100%;
    box-sizing: border-box;
  }

  input[type="file"] {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
  }

  input[type="file"] + label {
    display: inline-block;
    padding: 12px;
    font-size: 1rem;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
    cursor: pointer;
    transition: border-color 0.3s ease;
    height: 45px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
    min-width: 0;
    flex: 1;
  }

  input[type="file"] + label:hover {
    background-color: #f1f1f1;
  }

  @media (max-width: 768px) {
    input[type="file"] + label {
      padding: 10px;
      font-size: 0.9rem;
    }

    select {
      width: 100%;
      padding: 10px;
      font-size: 0.9rem;
    }
  }

  select {
    height: 45px;
    line-height: 21px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dish-flex label {
    display: block;
    margin-bottom: 5px;
  }
